<template>
  <div>
    <div class="form--group form--group-switch">
      <div class="left--col">
        <span>{{ $t('addListing.primary') }}</span>
        <span class="required">*</span>
      </div>
      <div class="right--col">
        <button
          type="button"
          @click="isConditionPrimaryValue = true"
          class="btn px-4"
          :class="{ selected: isConditionPrimaryValue }"
        >
          {{ $t('general.yes') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          @click="isConditionPrimaryValue = false"
          :class="{ selected: !isConditionPrimaryValue }"
        >
          <span style="position: relative;">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'rent-buy-switch',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      listingType: state => state.v2.listingForm.listingType,
    }),

    isConditionPrimaryValue: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.isConditionPrimary;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_CONDITION_PRIMARY', value);
      },
    },
  },
  watch: {
    isConditionPrimaryValue(value) {
      if (value) {
        this.$store.commit('v2/listingForm/sect1/SET_OPEN_OWNERSHIP', false);
      } else {
        this.$store.commit('v2/listingForm/sect1/SET_OPEN_OWNERSHIP', true);
      }
    },
  },

  methods: {},
};
</script>

<style scoped></style>
